import React, { useState, useContext, useEffect } from 'react';

import BoxIcon from '../../assets/vector/waifuMintIcons/boxIcon';
import BoxOpenIcon from '../../assets/vector/waifuMintIcons/boxOpenIcon';
import waifuMintIcon from "../../assets/vector/waifuMintIcons/waifuMintIcon.svg";

import Modal from "../elements/Modal";
import MintBox from "../elements/MintBox";
import UserContext from "../../context/userContext/UserContext";
import PaginationComponent from '../elements/PaginationComponent';
import Loader from '../../components/elements/ModalX/loader';

export default function Mint() {
    const [boxButton, setBox] = useState(<BoxIcon />);
    const [boxButtonFounder, setBoxFounder] = useState(<BoxIcon />);
    const [boxButtonFounderBronze, setBoxFounderBronze] = useState(<BoxIcon />);
    const { mintOpen, changeOpenMint, contract, account } = useContext(UserContext);

    const [listItemsFilter, setListItemsFilter] = useState(null)

    const [loading, setLoading] = useState(false)
    const [mintResult, setMintResult] = useState(null)

    const [viewResult, setViewResult] = useState(null)

    const [tractorInitialSize, setTractorInitialSize] = useState(0)
    const [waifuInitialSize, setWaifuInitialSize] = useState(0)

    const [modalFounder, setModalFounder] = useState(false)

    const [founder, setFounder] = useState(0)
    const [founderBronze, setFounderBronze] = useState(0)



    useEffect(() => {

        if (contract && account) {
            contract.ContractDwFounderBronze.methods.isFounder(account).call().then(result => { console.log("bronce", result); setFounderBronze(result) }).catch(err => setFounderBronze(false))
            contract.ContractDwFounder.methods.isFounder(account).call().then(result => { setFounder(parseInt(result)) }).catch(err => setFounder(0))
            contract.contractTractor.methods.getTractorIdsOf(account).call().then(result => { setTractorInitialSize(result) }).catch(err => setTractorInitialSize(0))
            contract.contractWaifu.methods.getFarmerIdsOf(account).call().then(result => { setWaifuInitialSize(result) }).catch(err => setWaifuInitialSize(0))
        }

    }, [contract, account])

    const changeOpenFounder = () => {


    }


    useEffect(() => {

        if (contract && account && mintResult) {
            setListItemsFilter(null);
            setLoading(true);
            const getMintItem = async () => {
                if (mintResult === "waifu") {
                    let result = await contract.contractWaifu.methods.getFarmerIdsOf(account).call()

                    let size = result.length - waifuInitialSize.length
                    const getAllWaifus = []
                    let tempResult = [...result]
                    tempResult.reverse().filter((element, i) => i < size && element !== "1000000000000000000" && getAllWaifus.push(contract.contractWaifu.methods.getFarmer(element).call()))
                    Promise.all(getAllWaifus).then(resultWaifu => {

                        const listAllWaifus = resultWaifu.map(element => ({ adn: '0'.repeat(61 - element.dna.length) + element.dna, ...element }))
                        console.log(listAllWaifus)
                        setListItemsFilter(listAllWaifus)
                        setWaifuInitialSize(result)
                        setViewResult(mintResult)
                        setMintResult(null)
                        setLoading(false)
                    }).catch(err => {
                        console.error(err)
                        setListItemsFilter([])
                        setLoading(false)
                        setViewResult(mintResult)
                        setMintResult(null)
                    })

                } else {
                    let result = await contract.contractTractor.methods.getTractorIdsOf(account).call()

                    let size = result.length - tractorInitialSize.length
                    const getAllTractor = []
                    let tempResult = [...result]


                    tempResult.reverse().filter((element, i) => i < size && element !== "1000000000000000000" && getAllTractor.push(contract.contractTractor.methods.getTractor(element).call()))
                    Promise.all(getAllTractor).then(resultTractor => {
                        setListItemsFilter(resultTractor)
                        setTractorInitialSize(result)
                        setViewResult(mintResult)
                        setLoading(false)
                        setMintResult(null)
                    }).catch(err => {
                        console.error(err)
                        setListItemsFilter([])
                        setViewResult(mintResult)
                        setLoading(false)
                        setMintResult(null)
                    })

                }
            }
            getMintItem()
        }
    }, [contract, account, mintResult])


    const mintBronze = async () => {

        if (contract) {
            setModalFounder(true)
            contract["ContractDwFounderBronze"].methods.mint().send({ from: account }).then(result => {

                let _id = result?.events["CallbackRequest"].returnValues._id || "";

                setTimeout(() => {

                    contract["ContractDwFounderBronze"].methods.resolveMint(_id, account).send({ from: account })
                        .catch(err => console.log(err))
                        .finally(() => setModalFounder(false))

                }, 5000);

            }).catch(err => setModalFounder(false))
        }

    }
    const mint = async () => {

        if (contract) {
            setModalFounder(true)
            console.log(contract.ContractDwFounder)
            contract["ContractDwFounder"].methods.mint().send({ from: account, value: Math.pow(10, 15) }).then(result => {

                let _id = result?.events["CallbackRequest"].returnValues._id || "";

                setTimeout(() => {

                    contract["ContractDwFounder"].methods.resolveMint(_id, account).send({ from: account })
                        .catch(err => console.log(err))
                        .finally(() => setModalFounder(false))

                }, 5000);

            }).catch(err => setModalFounder(false))
        }

    }

    const HaventWaifus = () => (
        <div className="grid grid-rows-mint justify-center text-center mt-16">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-auto mx-auto self-center"></img>
            <span className="font-bold text-2xl text-black">It Seems that You Don't Have Any Waifu at this moment!</span>
            <span className=" text-xl text-black">After you have minted your Farmer Waifus will appear here</span>
            <div className="p-3 bg-[#F0C1DC] w-60 rounded-md mx-auto mt-5">

                <button onClick={() => { if (typeof window !== 'undefined') window.location.href = "/mint" }} className="bg-[#AA2E74] w-52 h-12 mx-auto text-white font-bold text-xl rounded-md">
                    GO TO MINT
                </button>
            </div>
        </div>
    )

    const getFounderPass = ["Bronze", "Silver", "Gold", "Bronze + Silver", "Bronze + Gold", "Silver + Gold", "Bronze + Silver + Gold"]


    return (
        <div className="pt-8 px-10 relative">
            <Modal modal={mintOpen} setModal={changeOpenMint} screen={true}> {mintOpen && <MintBox setMintResult={setMintResult} />} </Modal>
            <Modal modal={modalFounder} setModal={setModalFounder} screen={true}> {modalFounder && <Loader />} </Modal>
            <section className="flex flex-wrap justify-around">

                <div className="text-center ">
                    <button className="font-bold text-lg text-center text-black bg-secondary mt-10 px-20 rounded-xl shadow-boxbutton hover:bg-sky-500 duration-200" onMouseEnter={() => setBox(<BoxOpenIcon />)} onMouseLeave={() => setBox(<BoxIcon />)}
                        onClick={() => {}/*changeOpenMint*/} >
                        {boxButton}
                        MINT
                    </button>
                </div>

                {founder > 0 ? <div className="text-center">
                    <button className="font-bold  mx-auto text-lg text-black bg-[#d1909b] mt-10 px-20 rounded-xl shadow-boxbutton hover:bg-sky-500 duration-200" onMouseEnter={() => setBoxFounder(<BoxOpenIcon />)} onMouseLeave={() => setBoxFounder(<BoxIcon />)}
                        onClick={() => {}/*mint*/}>
                        <div className="flex justify-center">{boxButtonFounder} </div>

                        FOUNDERS MINT  ({getFounderPass[founder - 1]})
                    </button>
                </div> : <> </>}
                {founderBronze ? <div className="text-center">
                    <button className="font-bold  mx-auto text-lg text-black bg-[#ac99e0] mt-10 px-20 rounded-xl shadow-boxbutton hover:bg-sky-500 duration-200" onMouseEnter={() => setBoxFounderBronze(<BoxOpenIcon />)} onMouseLeave={() => setBoxFounderBronze(<BoxIcon />)}
                        onClick={() => {}/*mintBronze*/}>
                        <div className="flex justify-center">{boxButtonFounderBronze} </div>

                        FOUNDERS MINT (BRONZE)
                    </button>
                </div> : <></>}


            </section>

            <div className="mt-8">
                <span className="font-bold text-lg text-primaryDark ml-4">Your MINT NFT's</span>
            </div>

            {!listItemsFilter
                ? <> {loading && <div className="h-80 flex justify-center items-center"><Loader /></div>} </>
                : <section className=" pt-20 pb-10"> {listItemsFilter.length > 0 ? <PaginationComponent item={viewResult === "waifu" ? "waifuMint" : "tractorInventory"} listItems={listItemsFilter} paginationCant={12} /> : <HaventWaifus />}  </section>
            }
        </div>
    )
}
