import React, { useState, useEffect, useContext } from "react";

import CardMint from "./CardMint";
import Button from "../elements/button";

import loadIcon from "../../assets/img/loadingIcon.webp";
import peachCoin from "../../assets/vector/peachCoin.svg";
import loliMintButton from "../../assets/vector/waifuMintIcons/waifuLoliIcon.png";
import tractorMintButton from "../../assets/vector/waifuMintIcons/waifuTractorIcon.svg";
import UserContext from "../../context/userContext/UserContext";
import contractAddress from "../../utils/Contract.json"
import Loader from "./ModalX/loader";

export default function MintBox({ setMintResult }) {
    const [price, setPrice] = useState('0');
    const [ammount, setAmmount] = useState(0);
    const [loading, setLoading] = useState(false)
    const [allowance, setAllowance] = useState(false)
    const { contract, mintOption, account, changeOpenMint, tractorMint, waifuMint } = useContext(UserContext)
    const [idResolveMint, setIdResolveMint] = useState(false)

    useEffect(() => {
        if (mintOption === "waifu") setPrice(ammount * waifuMint);
        else setPrice(ammount * tractorMint);

    }, [ammount, mintOption]);

    useEffect(() => {
        if (contract) contract.contractPeach.methods.allowance(account, process.env.GATSBY_DWGAME).call().then(result => parseInt(result) > 0 && setAllowance(true)).catch(err => console.error(err))
    }, [contract])

    const sucessMint = () => {
        setMintResult(mintOption)
        changeOpenMint(false);
        setIdResolveMint(null)
    }


    let resolveMint = async (result) => {

        let _id = result?.events["CallbackRequest"].returnValues._id || idResolveMint;

        setTimeout(() => {

            contract[mintOption === "waifu" ? "contractDWGameFarmer" : "contractDWGameTractor"].methods.resolveMint(_id, account).send({ from: account, value: mintOption === "waifu" ? (3 * ammount) + '0'.repeat(14) : (1 * ammount) + '0'.repeat(15) })
                .then(result2 => sucessMint())
                .catch(err => setIdResolveMint(_id))
                .finally(() => setLoading(false))

        }, 5000);
    }


    const mint = async () => {
        if (contract) {
            setLoading(true)
            if (mintOption === "none" && typeof window !== 'undefined') return window.alert("Please select farmer waifu or tractor")
            if (!allowance) await contract.contractPeach.methods.approve(process.env.GATSBY_DWGAME, 5 + '0'.repeat(24)).send({ from: account })

            contract[mintOption === "waifu" ? "contractDWGameFarmer" : "contractDWGameTractor"].methods.mint(ammount).send({ from: account, value: (3 * ammount) + '0'.repeat(14) }).then(result => resolveMint(result)).catch(err => { console.log(err); setLoading(false) })

        }
    }

    return <>
        {loading
            ? <div className="flex flex-col justify-center items-center w-[50vw] lg:w-[25vw] min-h-[40vh]">
                <Loader />
                <span className="text-[white] text-xl font-bold text-center pt-5">Don't leave the page or it could affect the minting process</span>
            </div>
            : <div className="  rounded-md py-3 flex flex-col justify-center items-center overflow-hidden w-[85vw] md:w-[55vw] min-h-[40vh] ">
                <div className="grid gap-y-8 md:grid-cols-2 items-center text-center text-backgroundOp text-2xl font-bold  ">
                    <div className="mx-8 ">
                        <h3 className="mb-3">Mint Waifu</h3>
                        <CardMint image={loliMintButton} card={"waifu"} />
                    </div>
                    <div className="mx-8" >
                        <h3 className="mb-3">Mint Tractor</h3>
                        <CardMint image={tractorMintButton} card={"tractor"} />
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center text-center bg-backgroundOp rounded-md font-bold my-8 mt-[3rem] md:mt-6">
                    <button className="text-2xl px-2 font-bold" onClick={() => setAmmount(ammount > 0 ? ammount - 1 : 0)}>-</button><h1 className="text-2xl px-12">{ammount}</h1><button className="text-2xl px-2 font-bold" onClick={() => setAmmount(ammount < 10 ? ammount + 1 : ammount)}>+</button>
                </div>
                <div className="flex flex-row justify-center items-center text-center">
                    <Button handleClick={mint}>
                        <span className="font-bold text-lg mr-1">BUY</span>
                        <span className="font-bold text-lg text-primaryDark">{price}</span>
                        <img src={peachCoin} className="w-6" alt="peach-icon" />
                    </Button>
                </div>
                {idResolveMint && <div className="bg-red-300 px-3 py-5 rounded-lg mt-5 cursor-pointer" onClick={() => { setLoading(true); resolveMint() }}>
                    <span className="text-red-600">To finish your mint, otherwise there might be an error, CLICK HERE!! </span>
                </div>}

            </div>
        }

    </>
}