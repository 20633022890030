import * as React from "react";
import Layout from "../components/layouts/Layout";
import MintComponent from "../components/Mint/Mint";
import backgroundHex from "../assets/img/backgroundSections/background.webp";

// markup
const Mint = () => {

    return (
        <Layout background={backgroundHex}>
            <MintComponent />
        </Layout>
    )
}

export default Mint
