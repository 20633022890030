import React, { useContext } from "react";
import UserContext from "../../context/userContext/UserContext";


export default function CardMint({ image, card }) {
    const { mintOption, changeMintOption } = useContext(UserContext);

    if (mintOption === card) {
        var style_selected = `shadow-[0px_0px_15px_0px_#fff] ${card === 'waifu' ? 'bg-primaryDark hover:bg-primaryDark' : 'bg-background hover:bg-background'}`;
    } else {
        var style_selected = `bg-primary ${card === 'waifu' ? 'hover:bg-primaryDark' : 'hover:bg-background'}`;
    }

    return (
        <div onClick={() => changeMintOption(card)} className={`${style_selected}  w-[180px] h-max items-center text-center hover:cursor-pointer rounded-lg transition duration-500`}>
            <img className="h-[168px] md:h-[226px] m-auto" src={image} alt="card-mint"></img>
        </div>
    )
}